.App {
  background: #ccc;
  font-family: "Bodoni MT", Didot, "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif;
  font-weight: bold;
  font-size: 34px;
  position: relative;
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

@media screen and (min-width: 1000px) {
  .App {
    /* Apply some styles */
    font-size: 100px;
  }
}

.verseObj {
  width: 100%;
  height: 100vh;
  background-size:  cover;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  cursor: pointer;
}

.verseObj h2 {
  text-align: center;
}

.verseObj h4 {
    padding: 24px;
    text-align: center;
}

.verseObj:hover .oneAnother {
    background-color: rgb(236, 236, 236);
}

.number {
  font-weight: bold;
  padding-right: 12px;
  color: #888;
  font-size: 80%;
}

.verses {
  padding: 48px;
}

.verses a {
  color: #555;
}


.reference {
  padding-left: 12px;
  font-size: 60%;
  color: #888;
}


footer {
  display: flex;
  flex-direction: column;
  color: #555;
  font-size: 50%;
}